<template>
  <div class="card">
    <div class="card-header border-0 pt-6 bg-white row m-0 sticky">
      <div class="d-flex p-0">
        <div class="card-title">
          <span>Create Produk Konsi</span>
        </div>
      </div>
    </div>
    <div class="card-body pt-0 tab-content text-gray-700" id="tab_raw_product">
      <el-form
        ref="formRef"
        :model="tempData"
        class="form w-100 my-5"
      >
        <div class="row fv-row mb-7">
          <div class="col me-10">
            <label class="required fs-6 fw-bold mb-2">Brand</label>
            <el-form-item
              class="fv-row mb-7"
              prop="brand_id"
              :rules="[
                { required: true, message: 'Brand is required' },
              ]"
            >
              <el-select name="brand_id" v-model="tempData.brand_id" class="form-control p-0 border-0" placeholder="Pilih brand" size="large">
                <el-option
                  v-for="(item, i) in listBrand"
                  :key="i"
                  :label="item.name"
                  :value="item.id"
                >
                  <div class="d-flex">
                    <div class="col me-3">{{ item.name }}</div>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
            <div class="fv-row mb-7">
              <label class="required fs-6 fw-bold mb-2">Kategori</label>
              <el-form-item
                class="fv-row mb-7"
                prop="category_id"
                :rules="[
                  { required: true, message: 'Category is required' },
                ]"
              >
                <el-select v-model="tempData.category_id" name="category_id" class="form-control p-0 border-0" placeholder="Pilih kategori" size="large"
                  @change="generateArtikel(), tempData.color_id = null, tempData.artikel = null, tempData.size = []"
                >
                  <el-option
                    v-for="(item, i) in listCategory"
                    :key="i"
                    :label="item.name"
                    :value="item.id"
                  >
                    <div class="d-flex">
                      <div class="col me-3">{{ item.name }}</div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="fv-row mb-7">
              <label class="required fs-6 fw-bold mb-2">Warna</label>
              <el-form-item
                class="fv-row mb-7"
                prop="color_id"
                :rules="[
                  { required: true, message: 'Color is required' },
                ]"
              >
                <el-select v-model="tempData.color_id" name="color_id" class="form-control p-0 border-0" placeholder="Pilih warna" size="large"
                  @change="generateArtikel(), tempData.size = []">
                  <el-option
                    v-for="(item, i) in listColor"
                    :key="i"
                    :label="item.name"
                    :value="item.id"
                  >
                    <div class="d-flex">
                      <div class="col me-3">{{ item.name }}</div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>

          <div class="col me-10">
            <div class="fv-row mb-7">
              <label class="required fs-6 fw-bold mb-2">Harga Jual</label>
              <el-form-item
                class="fv-row mb-7"
                prop="price"
                :rules="[
                  { required: true, message: 'Price is required' },
                ]"
              >
                <el-input v-model="tempData.price" name="price" class="form-control p-0 border-0" placeholder="Atur harga jual" size="large"></el-input>
              </el-form-item>
            </div>
            <div class="fv-row mb-7">
              <label class="required fs-6 fw-bold mb-2">Artikel</label>
              <el-form-item
                class="fv-row mb-7"
                prop="artikel"
                :rules="[
                  { required: true, message: 'Artikel is required' },
                ]"
              >
                <el-input v-model="tempData.artikel" name="artikel" class="form-control p-0 border-0" placeholder="Generate artikel" size="large" @change="generateMultiSku()"></el-input>
              </el-form-item>
            </div>
            <div class="fv-row mb-7">
              <label class="required fs-6 fw-bold mb-2">Size</label>
              <el-form-item
                class="fv-row mb-7"
                prop="size"
                :rules="[
                  { required: true, message: 'Size is required' },
                ]"
              >
                <el-select
                  name="size"
                  multiple
                  v-model="tempData.size"
                  class="form-control p-0 border-0"
                  placeholder="Pilih size"
                  size="large"
                  @change="generateMultiSku()"
                >
                  <el-option
                    v-for="(item, i) in listSize"
                    :key="i"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="col me-10">
            <div class="fv-row mb-7">
              <label class="required fs-6 fw-bold mb-2">Gambar</label>
              <el-form-item
                class="fv-row mb-7"
                prop="image"
                :rules="[
                  { required: true, message: 'Image is required' },
                ]"
              >
                <el-upload
                  ref="uploadImage"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleImageRemove"
                  :auto-upload="false"
                  :limit="1"
                  :on-change="handleImageChange"
                  :on-exceed="handleImageExceed"
                  name="image"
                >
                  <el-icon><Plus /></el-icon>
                  <template #tip>
                    <div class="el-upload__tip" style="line-height: 1.5">
                     Note: jpg/png files with a size less than or equal {{ Math.round(requiredImageSize / 1024 / 1000) }}MB
                    </div>
                    <div class="el-upload__tip" style="line-height: 1.5" v-if="uploadImage && uploadImage.uploadFiles.length > 0">
                      Filename: {{ uploadImage.uploadFiles[0].name }}
                    </div>
                  </template>
                </el-upload>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="mb-7" v-if="newSKU.length > 0">
          <h5 class="mb-7">List Size (SKU generate otomatis)</h5>
          <div class="row" >
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5" v-for="(size_id, i) in tempData.size" :key="i">
              <label class="fs-6 fw-bold mb-2 badge badge-light w-100">{{ listSize.find(o => o.id == size_id).name }} <span class="ms-2" v-if="typeof newSKU[i] != 'undefined'">({{ newSKU[i].sku }})</span></label>
              <el-input v-show="false" v-if="typeof newSKU[i] != 'undefined'" v-model="newSKU[i].qty" class="form-control p-0 border-0" placeholder="Qty" size="large"></el-input>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <button
              type="button"
              ref="submitButton"
               @click="onSubmit(formRef)"
              class="btn btn-primary w-350px mt-7 justify-content-end"
              :data-kt-indicator="loadingSubmit ? 'on' : 'off'"
          >
              <span class="indicator-label"> Submit </span>

              <span class="indicator-progress">
                  Please wait...
                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
          </button>
        </div>
      </el-form>
    </div>
    <el-dialog v-model="dialogVisible">
      <img class="w-100" :src="dialogImageUrl" alt="Preview Image" />
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
$color_primary: #EE7305;
$add_product_size: 180px;

.product-distribusi {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, $add_product_size));
  text-align: left;
  position: relative;
  height: 100%;
  overflow-y: scroll;
  padding: .5rem;
  gap: 1rem;
  grid-auto-rows: minmax(min-content, max-content);

  .list-product-distribusi {
    display: flex;
    flex-direction: column;

    img, span {
      margin-bottom: .5rem;
    }

    img {
      border: solid 1px #eee;
      width: 100%;
      height: $add_product_size;
    }

    span {
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  .list-product-distribusi.add-product-distribusi {
    height: $add_product_size;
    align-items: center;
    border: dashed 3px $color_primary;
    cursor: pointer;

    i {
      color: $color_primary !important;
      font-size: 200%;
      line-height: $add_product_size;
      width: 100%;
      text-align: center;
    }
  }

  .list-product-distribusi.add-product-distribusi:hover {
    border-style: solid;
  }
}
</style>

<script>
import { defineComponent, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { UploadProps, UploadUserFile } from 'element-plus'
import { formatIDR, validFileSize, validFileType } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import { ElMessage, ElLoading, ElPagination, FormInstance, FormRules } from 'element-plus'
import { Delete, Download, Plus, ZoomIn } from '@element-plus/icons-vue'



export default defineComponent({
  components: {
    //Datatable,
    //Field,
    //Form,
    //ErrorMessage,
    Plus,
  },
  setup() {
    const img_url_products = ref(process.env.VUE_APP_IMAGE_PRODUCTS)
    const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)

    const loadingTable = ref(true);
    const loadingSubmit = ref(false);
    const disabledButton = ref(false);

    const search = ref(null)

    const tempData = reactive({
      brand_id: null,
      category_id: null,
      artikel: null,
      color_id: null,
      price: null,
      user_id: null,
      size: [],
      image: null,
    })

    const newSKU = reactive([]);

    const formRef = ref(null)
    const submitData = reactive([])

    const listCategory = reactive([])
    const listBrand = reactive([])
    const listColor = reactive([])
    const listSize = reactive([])
    const listStore = reactive([])

    const getListCategory= async () => {
      try {
        await ApiService.get('category')
          .then((response) => {
            Object.assign(listCategory, response.data)
          })
          .catch((error) => {
            console.log('Error getting list brand');
          })

      } catch(e) {
        console.log(e.message)
      }
    }

    const getListBrand = async () => {
      try {
        await ApiService.get('brand')
          .then((response) => {
            Object.assign(listBrand, response.data)
          })
          .catch((error) => {
            console.log('Error getting list brand');
          })

      } catch(e) {
        console.log(e.message)
      }
    }

    const getListColor = async() => {
      try {
        await ApiService.setHeader();
        await ApiService.get("color")
          .then(({ data }) => {
            Object.assign(listColor, data)
          })
          .catch(({ response }) => {
            throw new Error('Error getting list color');
          });
      } catch(e) {
        console.log(e.message)
      }
    }

    const getListSize = async() => {
      try {
        await ApiService.setHeader();
        await ApiService.get("size")
          .then(({ data }) => {
            Object.assign(listSize, data)
          })
          .catch(({ response }) => {
            throw new Error('Error getting list size');
          });
      } catch(e) {
        console.log(e.message)
      }
    }

    const generateArtikel = async() => {
      let data = { raw_category_id: tempData.category_id, vendor_id: tempData.vendor_id, color_id: tempData.color_id }

      try {
        await ApiService.setHeader();
        await ApiService.post("product_raw/generate_kode", data)
          .then(({ data }) => {
            if(data.error) {
              throw Error(data.messages)
            }

            tempData.artikel = data.artikel
          })
          .catch((e) => {
            throw Error(e.message)
          });

      } catch(e) {
        Swal.fire({
          title: "Error",
          text: e.message,
          icon: "error",
          buttonsStyling: false,
          showConfirmButton: false,
          timer: 2000
        });
      }

      //loading.close()
    }

    const generateMultiSku = async() => {
      let data = { artikel: tempData.artikel, size: tempData.size }

      const loading = ElLoading.service({
        lock: true,
        text: 'Tunggu',
        background: 'rgba(255,255,255,0.7)'
      })

      try {
        if(!tempData.artikel) {
          Swal.fire({
            title: "Error",
            text: 'Input artikel terlebih dahulu',
            icon: "error",
            buttonsStyling: false,
            showConfirmButton: false,
            //timer: 2000
          });

          tempData.size = []

          loading.close()
          return
        }

        newSKU.splice(0);

        await ApiService.post("product_raw/generate_multi_sku", data)
          .then(async({ data }) => {
            if(data.error) {
              throw Error(data.messages)
            }

            let set = [];

            data.data.forEach((o, i) => {
              let getDet = newSKU.find(e => e.size_id == o.size);

              if(getDet) {
                set.push({ size_id: o.size, sku: o.sku, qty: getDet.qty })
              } else {
                set.push({ size_id: o.size, sku: o.sku, qty: null })
              }
            })

            await Object.assign(newSKU, set)
          })
          .catch((e) => {
            throw Error(e.message)
          });

      } catch(e) {
        Swal.fire({
          title: "Error",
          text: e.message,
          icon: "error",
          buttonsStyling: false,
          showConfirmButton: false,
          timer: 2000
        });
      }

      loading.close()
    }

    // File upload
    const uploadImage = ref(null)
    const dialogImageUrl = ref('')
    const dialogVisible = ref(false)
    const requiredImageSize = (0.5 * 2000) * 1024;

    const handleImageRemove = (uploadFile, uploadFiles) => {
      //console.log(uploadFile, uploadFiles)
      try {
        const uploadListEl = document.querySelector('.el-upload-list');
        const uploadEl = document.querySelector('.el-upload.el-upload--picture-card');

        uploadListEl.style.display = 'none'
        uploadEl.style.display = 'inline-block'

        uploadImage.value.uploadFiles.splice(0)
        tempData.image = null;

      } catch(e) {
        //console.error(e)
      }
    }

    const handlePictureCardPreview = (file) => {
      dialogImageUrl.value = file.url
      dialogVisible.value = true
    }

    const handleImageChange = (uploadFile, uploadFiles) => {
      try {

        if(uploadFiles.length > 1) {
          ElMessage.error(
            `The limit is 1, you selected ${uploadFiles.length} files this time.`
          )

          uploadImage.value.uploadFiles.splice(0)
          throw new Error('File length')
        }

        if(uploadFile.size > requiredImageSize) {
          ElMessage.error(
            `Selected file size is ${Math.round(uploadFile.size / 1024 / 1000)}MB. File size must be less than or equal ${Math.round(requiredImageSize / 1024 / 1000)}MB..`
          )

          uploadImage.value.uploadFiles.splice(0)
          throw new Error('File size')
        }

        tempData.image = uploadImage.value.uploadFiles[0].raw;
        console.log(tempData.image)

        const uploadListEl = document.querySelector('.el-upload-list');
        const uploadEl = document.querySelector('.el-upload.el-upload--picture-card');

        uploadListEl.style.display = 'inline-block'
        uploadEl.style.display = 'none'

      } catch(e) {
        //console.error(e)
      }
    }

    const handleImageExceed = (files) => {
      try {
        uploadImage.value.uploadFiles.splice(0)
        tempData.image = null;

      } catch(e) {
        console.error(e)
      }
    }

    // Submit Form
    const onSubmit = async (formEl) => {
      try {
        if (!formEl) return

        await formEl.validate( async(valid, fields) => {
          loadingSubmit.value = true;

          if (valid) {
            const formData = new FormData();

            const fdata = {
              'brand_id': tempData.brand_id,
              'category_id': tempData.category_id,
              'color_id': tempData.color_id,
              'price': tempData.price,
              'artikel': tempData.artikel,
              'sku': JSON.stringify(newSKU),
            }

            formData.append('image', tempData.image)

            Object.keys(fdata).forEach(k => {
              formData.append(k, fdata[k]);
            })

            await ApiService.post("product_raw/create_direct_product", formData)
            .then(async({ data }) => {
              if(data.error) {
                throw Error(data.messages)
              }

              Swal.fire({
                title: "Success",
                text: data.messages,
                icon: "success",
                buttonsStyling: false,
                showConfirmButton: false,
                timer: 2000
              });


              Object.keys(tempData).map(k => {
                if(k == 'size') {
                  tempData.size = []
                } else {
                  tempData[k] = null
                }
              })

              newSKU.splice(0)
              handleImageRemove()

            })
            .catch((e) => {
              Swal.fire({
                title: "Error",
                text: e.message,
                icon: "error",
                buttonsStyling: false,
                showConfirmButton: false,
                timer: 2000
              });
            });

          } else {
            throw new Error('Please check form before submit.')
          }

          loadingSubmit.value = false
        })

      } catch (e) {
        Swal.fire({
          title: "Error",
          text: e.message,
          icon: "error",
          buttonsStyling: false,
          showConfirmButton: false,
          timer: 2000
        });
      }
    }

    onMounted(() => {
      setCurrentPageTitle("Produk Konsi");
      setCurrentPageBreadcrumbs("Produk Konsi", ['Produk Konsi']);

      getListBrand()
      getListCategory()
      getListColor()
      getListSize()
    })

    watch(() => tempData.size, (newVal, olVal) => {
      if(newVal) {
        let set = [];

        // newVal.forEach((o, i) => {
        //   let getDet = newSKU.find(e => e.size_id == o);
        //
        //   if(getDet) {
        //     set.push({ size_id: o, sku: null, qty: getDet.qty })
        //   } else {
        //     set.push({ size_id: o, sku: null, qty: null })
        //   }
        // })
        //
        // Object.assign(newSKU, set)
      }
    })

    watch(() => newSKU, (val) => {
      console.log(val)
    })

    return {
      img_url_desain, img_url_products, disabledButton, loadingSubmit,
      formRef,
      tempData, submitData, listCategory, listStore, listBrand, listColor, listSize,
      uploadImage, dialogImageUrl, dialogVisible, handleImageRemove, handlePictureCardPreview, handleImageChange, handleImageExceed, requiredImageSize,
      onSubmit, generateArtikel, generateMultiSku, newSKU,
    }
  }
})
</script>